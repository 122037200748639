.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: var(--modal-barrier-color);
    opacity: 0;
    transition: opacity 0.3s;
}

.modal_visible {
    opacity: 1;
}

.modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    overflow: auto;
    max-width: 90vw;
    max-height: 80vh;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 1rem;
    background-color: var(--background-color1);
    box-shadow: 0.25rem 0.5rem 2rem var(--grey2);
    scale: 0.9;
    transition: scale 0.4s;
}

.modal_content_visible {
    scale: 1;
}
