:root {
  --system-font-family: -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Ubuntu', sans-serif;
}

:root {
  --white255: rgb(255, 255, 255);
  --white245: rgb(245, 245, 245);

  --black20: rgb(20, 20, 20);
  --black40: rgb(40, 40, 40);

  --grey80: rgb(80, 80, 80);
  --grey200: rgb(200, 200, 200);

  --cafe-au-lait-brown: #A5785E;
  --cafe-au-lait-brown-dark: #866255;
  --cafe-au-lait-brown-light: #DBCBBB;

  --earth-yellow: #DEAF5F;
  --earth-yellow-dark: #CA7923;
  --earth-yellow-light: #F0DCB7;

  --palm-leaf-green: #7AA433;
  --palm-leaf-green-dark: #264F0A;
  --palm-leaf-green-light: #BCCF9A;

  --white255-a75: rgba(255, 255, 255, 0.75);
  --white235-a50: rgba(235, 235, 235, 0.5);
}

:root {
  --background-color1: var(--white255);
  --background-color2: var(--white245);

  --foreground-color1: var(--black20);
  --foreground-color2: var(--black40);

  --grey1: var(--grey80);
  --grey2: var(--grey200);

  --primary-color: var(--cafe-au-lait-brown);
  --primary-container-color: var(--cafe-au-lait-brown-light);

  --secondary-color: var(--earth-yellow);
  --secondary-container-color: var(--earth-yellow-light);

  --tertiary-color: var(--palm-leaf-green);
  --tertiary-container-color: var(--palm-leaf-green-light);

  --background-color1-a75: var(--white255-a75);
  --modal-barrier-color: var(--white235-a50);
}

:root {
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  :root {
    font-size: 12px;
  }
}

body {
  margin: 0;
  font-family: var(--system-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--foreground-color2);
  background-color: var(--background-color1);
}

html {
  scroll-behavior: smooth;
}

a {
  font-weight: 600;
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.small_heading {
  font-size: 1.1rem;
  font-weight: normal;
  text-transform: capitalize;
  margin: 0;
}

.primary_btn {
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  color: var(--background-color2);
  background-color: var(--primary-color);
  border: none;
  padding: 0.75rem;
  border-radius: 0.5rem;
  transition: scale 0.3s;
}

@media (hover: hover) {
  .primary_btn:hover {
    outline: 0.15rem solid var(--primary-color);
    outline-offset: 0.15rem;
  }
}

.primary_btn:active {
  scale: 0.9;
}

.primary_btn:disabled {
  background-color: var(--primary-container-color);
}

.icon_btn {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  transition: background 0.2s;
}

.icon_btn img {
  width: 100%;
}

@media (hover: hover) {
  .icon_btn:hover {
    background-color: var(--background-color2);
  }
}

.icon_btn:active {
  background-color: var(--background-color2);
  scale: 0.9;
}
