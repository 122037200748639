.features {
    max-width: 1280px;
    margin: auto;
    padding: 5rem 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

/* Picture placeholder */
.features>div:nth-child(1) {
    width: 16rem;
    max-width: 80%;
    aspect-ratio: 48/95;
    margin: auto;
    border-radius: 2rem;
    background-color: var(--background-color2);
}

.features>div:nth-child(1) img {
    width: 100%;
    vertical-align: bottom;
}

/* Description */
.features>div:nth-child(2) {
    margin: auto;
}

.features h2 {
    color: var(--secondary-color);
}

.features h3 {
    font-size: 2rem;
    text-transform: capitalize;
    margin-top: 0.5rem;
    margin-bottom: 0;
}

@media screen and (max-width: 920px) {
    .features {
        grid-template-columns: 1fr 1.5fr;
    }
}

@media screen and (max-width: 640px) {
    .features {
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
    }

    .features :where(h2, h3) {
        text-align: center;
    }

    .features h3 {
        font-size: 1.5rem;
    }
}
