.header {
    max-width: 1280px;
    margin: auto;
    padding: 2rem 1rem 0;
}

.header>div {
    padding: 8rem 2rem;
    border-radius: 1rem;
    background: url(../../../assets/images/student_room.png),
        var(--background-color2);
    background-size: 40rem 22.5rem;
    background-position: top right;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header h1 {
    color: var(--primary-color);
}

.header h2 {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: capitalize;
    max-width: 720px;
    margin-top: 0.5rem;
}

@media screen and (max-width: 1080px) {
    .header>div {
        padding-top: 12rem;
    }
}

@media screen and (max-width: 800px) {
    .header>div {
        padding-bottom: 4rem;
        background-size: 28rem 15.75rem;
    }
}

@media screen and (max-width: 640px) {
    .header h2 {
        font-size: 2rem;
    }
}
