.download {
    padding: 5rem 1rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.download h2 {
    color: var(--tertiary-color);
}
