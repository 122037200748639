.footer {
    max-width: 1280px;
    margin: auto;
    padding: 1rem;
    font-size: 0.85rem;
    color: var(--grey1);
    display: flex;
    flex-wrap: wrap;
    gap: 0 0.7rem;
}

.footer p {
    margin: 0;
}

.footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0 0.6rem;
}

.footer li a {
    font-weight: normal;
    color: inherit;
    text-decoration: underline;
    transition: all 0.2s;
}

.footer li a:hover {
    color: var(--primary-color);
}
